<template>
  <section
    id="introduction"
    class="overflow-hidden frame"
    align="center"
    justify="center"
  >
    <v-row>
      <v-col
        class="align  pa-13 justify"
        cols="12"
      >
        <base-heading>
          A-Z Totalunternehmung
        </base-heading>
        <br><br>
        <base-subheading class="font-weight-bold">
          Der Bau &amp; Umbauspezialist
        </base-subheading>
        <!--base-btn class="mt-4">
          Referenzen
        </base-btn-->
      </v-col>
    </v-row>
  </section>
</template>

<script>
  export default {
    name: 'Introduction',
  }
</script>

<style scoped>
.frame{
  .margin: 30px;

}
</style>
